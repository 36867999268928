@import '../styles/abstracts/vars'
@import '../styles/abstracts/mixins'

  
.app.tour .hide-on-tour
  visibility: hidden

.tour-panel
  position: absolute
  height: calc(var(--vh, 1vh) * 100)
  width: 100%
  pointer-events: none

  .wrap
    background-color: theme('colors.red.50')
    position: absolute
    width: 100%
    z-index: 10
    box-shadow: toRem(0px)  toRem(-4px) toRem(20px) toRem(0px) #00000080
    bottom: toRem(0px)
    button
      pointer-events: all

  .text-panel
    position: absolute
    background-color: theme('colors.red.25')
    transform: scale(0.5)
    opacity: 0
    transition: transform 300ms, opacity 300ms
    max-width: toRem(400px)
    
    &.TL
      top: toRem(128px)
      left: toRem(32px)
    &.TM
      top: toRem(128px)
      left: 50%
    &.TR
      top: toRem(128px)
      right: toRem(64px)

  
    &.BL
      bottom: toRem(96px)
      left: toRem(32px)
    &.BM
      bottom: toRem(96px)
      left: 50%
    &.BR
      bottom: toRem(96px)
      right: toRem(32px)

    &.FOOTER
      bottom: toRem(64px)
      right: toRem(0px)



    &.show
      transform: scale(1)
      opacity: 1

    h1
      font-size: toRem(45px)
      line-height: 110%
