.map-handler
  position: absolute
  overflow: hidden
  top: 0
  left: 0
  bottom: 0
  right: 0

  .hide-on-story
    transition: opacity 300ms
    opacity: 1

  &.in-story .hide-on-story
    pointer-events: none
    opacity: 0


  .map-debug-tag 
    position: absolute
    color: #ff0
    bottom: toRem(10px)
    right: toRem(10px)
    text-align: right

  .marker-wrap
    &.city
      .uhaft, .bz, .gericht
        display: none
      
    &.area
      .bezirksstadt, .revolution, .geschichten, .mfs
        display: none

  .marker
    position: absolute
    width: toRem(12px)
    height: toRem(12px)
    border-radius: toRem(40px)
    transform: translate(-50%,-100%)
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    transition: opacity 300ms, transform 300ms
    transform-origin: 50% 100%
    opacity: 1

    &.dimmed
      opacity: 0.2
    &.selected
      opacity: 1
      transform: translate(-50%,-100%) scale(2) 

    &.blue
    &.red
    &.blue

    &.bezirksstadt
      background-color: theme('colors.blue.50')
      span.cat 
        background-color: theme('colors.blue.50')
    &.revolution
      background-color: theme('colors.pink.50')
    &.geschichten
      background-color: theme('colors.petrol.50')
    &.mfs
      background-color: theme('colors.red.50')
    &.uhaft
      background-color: theme('colors.petrol.50')
    &.bz
      background-color: theme('colors.petrol.50')
    &.gericht
      background-color: theme('colors.petrol.50')

    &.text 
      pointer-events: none
      z-index: 0
      span
        position: absolute
        //display: none
        text-transform: uppercase
        font-weight: bold
        font-size: toRem(12px)
        line-height: 100%
        text-align: center
        left: 50%
        transform: translateX( -50% )
        &.cat
          display: none

    &.point span      
      position: absolute
      z-index: 1
      pointer-events: none
      //display: none
      text-transform: uppercase
      //font-weight: bold
      font-size: toRem(16px)
      line-height: 100%
      text-align: left
      left: 50%
      bottom: calc(100% - toRem(15px))
      transform: translateX( 0% )
      opacity: 0
      transition: opacity 0.2s, bottom 0.2s
      //text-shadow: 0 toRem(2px) toRem(4px) rgba(0,0,0,0.8)
      background-color: theme('colors.uigrey.darkhover')
      padding: toRem(10px)
      &:after
        content: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13V0H13L0 13Z' fill='%23657278'/%3E%3C/svg%3E%0A")
        position: absolute
        top: calc(100% - toRem(2px))
        left: 0
      @include below-wi(mobile)
        font-size: toRem(12px)

      &.title
        display: none

    &:hover 
      z-index: 999
      &.point span
        //display: block
        opacity: 1
        bottom: calc(100% + toRem(15px))


  &.bezirksstadt,&.revolution,&.geschichten,&.mfs,&.uhaft,&.gericht,&.bz
    .marker
      //z-index: 1

  &.bezirksstadt
    .marker.bezirksstadt      
      width: toRem(40px)
      height: toRem(40px)
      background-color: transparent
      background-image: url("data:image/svg+xml,%3Csvg width='64' height='99' viewBox='0 0 64 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M63.3087 32.4919C63.3087 49.9741 31.6543 98.9039 31.6543 98.9039C31.6543 98.9039 0 49.9741 0 32.4919C0 15.0097 14.1721 0.837524 31.6543 0.837524C49.1365 0.837524 63.3087 15.0097 63.3087 32.4919Z' fill='%23227CAD'/%3E%3Ccircle cx='31.6544' cy='29.8022' r='20.0684' fill='%23FAFAFA'/%3E%3C/svg%3E%0A")
      .title 
        display: block
      .cat
        display: none        

  &.revolution
    .marker.revolution
      width: toRem(40px)
      height: toRem(40px)
      background-image: url("data:image/svg+xml,%3Csvg width='110' height='110' viewBox='0 0 110 110' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='54.8707' cy='54.8707' r='54.8707' fill='%23AD287D'/%3E%3Cpath d='M69 53.0354C69 49.1985 68.4909 48.818 64.7364 49.6424C61.8727 50.2766 58.9773 50.8474 55.6364 51.545C55.6364 49.1668 55.7318 47.2959 55.5727 45.4567C55.5091 44.7591 54.8727 44.0932 54.5227 43.4272C54.1727 44.1566 53.5682 44.8225 53.5364 45.5835C53.3773 47.4227 53.4727 49.2936 53.4727 51.6402C50.2591 50.9425 47.5545 50.4352 44.85 49.7693C41.4136 48.9448 41 49.1985 41 52.6232C41 65.5926 41 78.562 41 91.5314C41 95.5269 42.0182 96.7002 46.0909 96.7953C52.0409 96.9222 58.0227 96.9222 63.9727 96.7953C67.9818 96.7002 68.9682 95.4952 68.9682 91.4363C69 78.6254 69 65.8146 69 53.0354Z' fill='%23FAFAFA'/%3E%3Cpath d='M47.9046 45.8055C43.9591 37.6243 47.6182 27.3503 55.8591 23.8305C55.3182 29.0943 57.45 33.2801 60.9818 36.9267C64.3546 40.4148 64.4818 41.3661 62.2864 45.5836C66.8046 43.4273 68.5864 39.8758 66.5818 36.1657C62.7318 28.9992 60.0909 21.6107 60.5364 12.8588C58.4046 14.3491 56.5909 15.459 54.9046 16.7908C50.1318 20.5643 45.7728 24.7183 43.6091 30.6164C41.35 36.7047 43.0682 42.7297 47.9046 45.8055Z' fill='%23FAFAFA'/%3E%3C/svg%3E%0A")
      .title 
        display: block
      .cat
        display: none

  &.geschichten
    .marker.geschichten
      width: toRem(40px)
      height: toRem(40px)
      background-image: url("data:image/svg+xml,%3Csvg width='111' height='111' viewBox='0 0 111 111' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='55.847' cy='55.6364' r='54.8707' fill='%2363B9AD'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M24.9274 84.0923C26.6225 83.308 28.3462 82.5679 30.0663 81.8293C30.8723 81.4833 31.6775 81.1376 32.4786 80.7878C36.2355 79.1598 39.3037 76.9682 40.8065 73.0234C40.1416 72.529 39.4164 72.1135 38.6965 71.7011C36.7725 70.5988 34.8868 69.5185 34.2945 67.0123C34.2318 66.8871 34.044 66.6366 33.9188 66.6366C30.9229 67.0772 29.8799 65.1618 28.8487 63.2679C28.4143 62.4702 27.982 61.6763 27.4067 61.0638C26.9482 60.5636 26.8504 59.7304 26.7439 58.8228C26.6904 58.3671 26.6348 57.8926 26.5301 57.4321H29.16C24.4012 46.4118 28.7217 36.8316 33.3552 27.5019C35.6094 22.9309 39.2411 19.174 43.9373 16.8572C45.5653 16.0432 48.1951 15.3544 49.5727 16.1058C52.3904 17.546 54.3315 16.4815 56.523 15.2292H60.9061C66.4163 17.7338 70.7368 21.6786 74.5563 26.2495C80.4422 33.3251 81.444 41.5904 80.9431 50.3566C80.7553 53.2368 80.8179 56.1797 80.8805 59.1226V59.1228C80.8805 59.539 80.9948 59.9551 81.1129 60.3852C81.1903 60.6668 81.2693 60.9543 81.3188 61.2517C81.3739 61.5641 81.4668 61.9089 81.5595 62.2528C81.7825 63.0806 82.0042 63.9033 81.6945 64.2572C81.1606 64.8802 80.2788 65.2186 79.3188 65.5869C78.9279 65.7369 78.524 65.8919 78.1254 66.0731C78.689 68.202 77.4366 71.0197 74.3059 72.2094C74.0569 72.304 73.8072 72.4006 73.5566 72.4975C70.6554 73.6195 67.6353 74.7874 64.35 73.4617C63.035 79.3476 63.2229 79.5981 68.6078 81.7896C69.8758 82.3062 71.1555 82.8032 72.4352 83.3002C75.5092 84.4941 78.5832 85.688 81.4945 87.1531C74.137 92.7327 64.9643 96.0435 55.0183 96.0435C43.3692 96.0435 32.7808 91.5017 24.9274 84.0923Z' fill='%23FAFAFA'/%3E%3C/svg%3E%0A")
      .title 
        display: block
      .cat
        display: none
  &.mfs
    .marker.mfs
      width: toRem(40px)
      height: toRem(40px)
      background-image: url("data:image/svg+xml,%3Csvg width='110' height='111' viewBox='0 0 110 111' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='54.8707' cy='55.685' r='54.8707' fill='%23BF332F'/%3E%3Ccircle cx='54.8707' cy='37.5658' r='15.1634' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M48.5926 47.23C48.1294 47.23 47.7268 47.5481 47.6197 47.9988L39.9999 80.0534C39.8506 80.6817 40.327 81.2847 40.9728 81.2847H54.2701H55.4714H68.7687C69.4144 81.2847 69.8909 80.6817 69.7415 80.0534L62.1218 47.9988C62.0147 47.5481 61.6121 47.23 61.1489 47.23H55.4714H54.2701H48.5926Z' fill='white'/%3E%3C/svg%3E%0A")
      .title 
        display: block
      .cat
        display: none

  &.uhaft,&.gericht,&.bz
    .marker
      transition: width 400ms, height 400ms
    .marker.uhaft
      width: toRem(10px)
      height: toRem(10px)
      background-image: url("data:image/svg+xml,%3Csvg width='110' height='110' viewBox='0 0 110 110' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='54.871' cy='54.8707' r='54.8707' fill='%2363B9AD'/%3E%3Cpath d='M98.7279 54.8707C98.7279 79.0922 79.0925 98.7276 54.871 98.7276C30.6496 98.7276 11.0142 79.0922 11.0142 54.8707C11.0142 30.6492 30.6496 11.0138 54.871 11.0138C79.0925 11.0138 98.7279 30.6492 98.7279 54.8707Z' fill='%23FAFAFA'/%3E%3Ccircle cx='54.871' cy='54.8706' r='21.3875' fill='%2363B9AD'/%3E%3C/svg%3E%0A")      
      .title 
        display: block
      .cat
        display: none
    .marker.bz
      width: toRem(10px)
      height: toRem(10px)
      background-image: url("data:image/svg+xml,%3Csvg width='110' height='110' viewBox='0 0 110 110' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='54.871' cy='54.8707' r='54.8707' fill='%2363B9AD'/%3E%3Cpath d='M98.7279 54.8707C98.7279 79.0922 79.0925 98.7276 54.871 98.7276C30.6496 98.7276 11.0142 79.0922 11.0142 54.8707C11.0142 30.6492 30.6496 11.0138 54.871 11.0138C79.0925 11.0138 98.7279 30.6492 98.7279 54.8707Z' fill='%23FAFAFA'/%3E%3Ccircle cx='54.871' cy='54.8706' r='21.3875' fill='%2363B9AD'/%3E%3C/svg%3E%0A")      
      .title 
        display: block
      .cat
        display: none
    .marker.gericht
      width: toRem(10px)
      height: toRem(10px)
      background-image: url("data:image/svg+xml,%3Csvg width='110' height='110' viewBox='0 0 110 110' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='54.871' cy='54.8707' r='54.8707' fill='%2363B9AD'/%3E%3Cpath d='M98.7279 54.8707C98.7279 79.0922 79.0925 98.7276 54.871 98.7276C30.6496 98.7276 11.0142 79.0922 11.0142 54.8707C11.0142 30.6492 30.6496 11.0138 54.871 11.0138C79.0925 11.0138 98.7279 30.6492 98.7279 54.8707Z' fill='%23FAFAFA'/%3E%3Ccircle cx='54.871' cy='54.8706' r='21.3875' fill='%2363B9AD'/%3E%3C/svg%3E%0A")      
      .title 
        display: block
      .cat
        display: none
    &.gericht .marker.gericht
      width: toRem(40px)
      height: toRem(40px)
    &.uhaft .marker.uhaft
      width: toRem(40px)
      height: toRem(40px)
    &.bz .marker.bz
      width: toRem(40px)
      height: toRem(40px)



@keyframes slideinTransform
  from
    right: 0
    transform: translateX(100%)
  to
    right: toRem(64px)
    transform: translateX(0%)


@keyframes slideoutTransform
  from
    right: toRem(64px)
    transform: translateX(0%)
  to
    right: 0
    transform: translateX(100%)


@keyframes panelFadein
  from
    opacity: 0
  to
    opacity: 1

@keyframes panelFadeout
  from
    opacity: 1
  to
    opacity: 0



.location-panel

  .cover
    background: linear-gradient(90deg, rgba(01,01,01,0.2) 40%, rgba(01,01,01,0.95) 60%)
    animation-name: panelFadein
    animation-duration: 300ms
    animation-fill-mode: forwards
    @include below-wi(mobile)
      background: linear-gradient(180deg, rgba(01,01,01,0.2) 50%, rgba(01,01,01,0.80) 90%)

  .panel
    position: absolute
    display: flex
    flex-direction: column
    top: toRem(128px)
    bottom: toRem(64px)
    right: toRem(64px)
    min-width: toRem(400px)
    width: 40%
    max-width: toRem(768px)
    animation-name: slideinTransform
    animation-duration: 0.4s
    animation-fill-mode: forwards
    transform: translateX(100%)

    @include below-wi(mobile)
      top: auto
      bottom: 0
      right: 0
      left: 0
      width: 100%
      min-width: auto
      .close-btn
        top: -40px
        background-color: transparent

    .skew-cover
      height: calc(100% + 64px )
      width: 100%
      transform: skew(0,-6deg)
      transform-origin: top left
      top: 0

      @include below-wi(mobile)
        height: 120%
        transform: skew(0,-6deg)

  &.close
    .panel 
      animation-name: slideoutTransform
    .cover
      animation-name: panelFadeout

