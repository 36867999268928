@keyframes slideUp
  from
    transform: translateY(100%)
  to
    transform: translateY(0)
  

.Overlay-delay-200ms
  animation-delay: 200ms  
  
.Overlay-slide-up
  animation-name: slideUp
  animation-duration: 0.4s
  animation-fill-mode: forwards  

.OverlayWindow
  width: 100%
  height: 100%
  position: absolute
  background-color: theme('colors.uigrey.dark')
  animation-name: slideUp
  animation-duration: 0.4s
  animation-fill-mode: forwards  

  .text-content
    max-height: calc(100% - 64px)
    .inner
      max-height: calc(100vh - 64px - 64px - 64px)
      overflow-y: scroll

