@each $key, $size in $containersWidth
    .na-#{$key}
        width: $size
        margin-left: auto
        margin-right: auto
        max-width: 100%
        @include below-wi(mobile) 
            padding-left: 1rem
            padding-right: 1rem

            &.no-mobile-padding
              padding-left: 0
              padding-right: 0

        &.no-padding
          padding: 0
        &.px-0
          padding-left: 0
          padding-right: 0