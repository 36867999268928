@import '../../../styles/abstracts/vars'
@import '../../../styles/abstracts/mixins'
  
.doc-zoomer
  .wrap
    width: 100%
    height: 100%
  .react-transform-component img
    width: auto
      




.btn-zoom
  @include below-wi(mobile)
    bottom: 0
    padding-right: 0
    right: toRem(128px)