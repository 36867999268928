@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'

  
.page
  //padding-top: toRem(128px)
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  overflow-y: auto
  //background-color: #343F45

  .container
    max-width: toRem(960px)
    margin-left: auto
    margin-right: auto

    &.full-width 
      max-width: 100%

  .header-cover
    //background-color: transparentize(#343F45,0.4)
    height: toRem(128px)
    width: calc(100% - var(--scrollbar))
    //opacity: 0.9
    z-index: 2
    backdrop-filter: blur(toRem(10px))
    @include below-wi(mobile)
      height: toRem(96px)

  .page-wrapper
    padding-top: toRem(128px)
    @include below-wi(mobile)
      padding-top: toRem(96px)

  .section
    @include below-wi(mobile)
      padding-left: toRem(16px)
      padding-right: toRem(16px)


  .section.full-width 
    @include below-wi(mobile)
      padding-left: 0
      padding-right: 0

    >.container
      max-width: 100%
  .section.section-image
    min-height: toRem(350px)

  a
    color: theme('colors.petrol.50')

  .bg-kubus-50,.bg-kubus-25 
    a
      color: theme('colors.kubus.110')

  .page-image
    min-height: toRem(350px)
    position: relative
    height: toRem(350px)
    background-color: #333
    &.section-background
      position: absolute
      width: 100%      
    img
      width: 100%
      height: 100%
      object-fit: cover
    svg
      max-width: 100%
    &.auto-height
      height: unset

  h1,h2,h3,h4,h5,h6
    line-height: 100%
    z-index: 1
    position: relative
    max-width: toRem(960px)
    margin-left: auto
    margin-right: auto

  h1
    font-size: toRem(80px)
  h2
    font-size: toRem(60px)
  h3
    font-size: toRem(40px)


  .text
    font-size: toRem(24px)
    padding-top: toRem(20px)
    padding-bottom: toRem(40px)

  button 
    display: flex
    padding-right: toRem(32px)
    align-items: center

  @include below-wi(mobile)

    .text
      font-size: toRem(18px)
      padding-bottom: toRem(40px    )

    h1
      font-size: toRem(35px)
    h2
      font-size: toRem(30px)
    h3
      font-size: toRem(28px)

