.story-handler
  position: absolute

  .story-bg
    outline: none

  &.red 
    .story-color
      @apply text-red-100
    .story-bg
      @apply bg-red-100

  &.pink
    .story-color
      @apply text-pink-100
    .story-bg
      @apply bg-pink-100

  &.petrol
    .story-color
      @apply text-petrol-50
    .story-bg
      @apply bg-petrol-50

  
