button 
  color: #fff
  &:hover
    color: rgba(255,255,255,0.5)


.btn
  transition: transform 400ms
  transform: scale(1)
  cursor: pointer
  &:hover
    transform: scale(0.98)
  >*
    pointer-events: none
  
  
.btn-icon
  display: flex
  align-items: center
  padding-right: toRem(32px)
  height: toRem(64px)
  &:hover 
    
.btn-icon-only
  display: flex
  align-items: center
  height: toRem(64px)

.btn-hover-scale
  transform: scale(1)
  transition: transform 500ms ease
  &:hover
    transform: scale(0.95)    



.hover-scale
  transition: transform 400ms !important

  transform: scale(1) !important
  pointer-events: all
  &:hover
    transform: scale(0.98) !important



.btn-overlay
  display: inline-flex
  align-items: center
  padding-right: toRem(32px)
  //border-radius: toRem(64px)
  //border: toRem(4px solid #fff)
  white-space: nowrap

  &.small
    padding: toRem(8px) toRem(20px) toRem(8px) toRem(8px)
    svg
      width: toRem(32px)
      height: toRem(32px)