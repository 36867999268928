.scrollstory,.scrollmap

  button
    pointer-events: all


  .scroll-progress-bar
    position: absolute
    width: 100%
    height: toRem(8px)
    z-index: 2
    >.block
      height: toRem(8px)
      transition: width 800ms
    &.petrol
      @apply bg-petrol-10
      >.block
        @apply bg-petrol-50
    &.pink
      @apply bg-pink-10
      >.block
        @apply bg-pink-50
    &.blue
      @apply bg-blue-10
      >.block
        @apply bg-blue-50
    &.kubus
      @apply bg-kubus-10
      >.block
        @apply bg-kubus-50
    &.red
      @apply bg-red-10
      >.block
        @apply bg-red-50


  .fixed-bgs
    background-color: rgba(0,0,0,0.2)
    .hide-bg
      opacity: 0
    .show-bg
      opacity: 1

  .swiper-container
    background-color: #000

  .swiper-slide
    width: 100%
    [class*="stretch-bg"] .img-wrap
      width: 80%

    .stretch-bg-1 .back
      width: 100%
    .stretch-bg-2 .back
      width: 200%
    .stretch-bg-3 .back
      width: 300%

  .dot-holder
    @include below-wi(mobile)
      padding-top: toRem(12px )
      padding-bottom: toRem(12px )

    .swiper-pagination-bullet
      display: block
      width: toRem(4px)
      height: toRem(4px)
      background-color: #000
      border-radius: toRem(4px)
      margin: toRem(3px)
      opacity: 1
      box-shadow: 0 0 toRem(4px) toRem(1px) #000

      &.swiper-pagination-bullet-active
        opacity: 1
        background-color: #fff
        .petrol &
          background-color: #fff


  .back
    >:first-child
      //transform: skewX(-10deg) translateX(5%)
      transform: skewX(-10deg) translateX(0%)
      transform-origin: bottom left
      overflow: hidden
      @include below-wi(mobile)
        transform: none
      >div
        transform: skewX(10deg)
        width: 116%
        transform-origin: bottom left
        @include below-wi(mobile)
          transform: none
      //left: -5%

    svg
      height: 100%
      width: auto
    .mask
      mask-size: cover
      &.side
        mask-image: url(side.svg)

  .extra-wide .back
    width: 110%
    left: -11%
    @include below-wi(mobile)
      width: 100%
      left: 0%

  .overlay
    position: absolute
    top: 0
    left: 0
    z-index: 10

  .content 
    top: toRem(128px)
    bottom: toRem(64px)
    z-index: 1
    @include below-wi(mobile)
      top: calc(8px + #{toRem(64px)} )

  .story-title
    //^font-weight: 100
    text-transform: uppercase
    //font-size: toRem(100px)
    //line-height: 100%
    font-size: 5vw
    line-height: 100%
    white-space: normal
    word-break: normal

    @include below-wi(mobile)
      //      font-size: toRem(40px)
      font-size: 10vw


  .story-subtext      
    
    font-size: toRem(32px)
    line-height: 130%
    max-width: 80%
    //color: #ff0000 !important

    @include below-wi(tablet)
      max-width: 80%

    @include below-wi(mobile)
      font-size: toRem(16px)
      max-width: 100%

  .text-body-wrapper
    overflow-y: scroll

  .text-body
    font-size: toRem(30px)
    max-width: 60vw

    @include below-wi(mobile)
      font-size: initial
      max-width: calc(100% - toRem(24px))

  .content-image 
    top: 50%
    left: 50%
    img
      height: 100%
      width: auto
      transform: translate(-50%,-50%)
      max-width: unset
      //box-shadow: 0 toRem(4px toRem(20px 0 #00000080)


  &.threesixty    
    .navigation
      button.dot-wrap
        &.selected .dot
          border: 1px solid #fff

      .dot
        width: toRem(32px)
        height: toRem(32px)
        position: relative
        transition: transform 300ms        
        border-radius: toRem(32px)
        &:hover
          &:after
            background-color: #f00
        &:after
          position: absolute
          content: ''
          width: toRem(8px)
          height: toRem(8px)
          border-radius: toRem(48px)
          top: 50%
          left: 50%
          transform: translate(-50%,-50%)
          background-color: #fff


.scrollbar-right-padding
  padding-right: toRem(61px)
  @include below-wi(mobile)
    padding-right: toRem(28px)

.edge-shadow-wrap
  transform: skewX(-10deg) translateX(5%)
  @include below-wi(mobile)
    transform: none
    display: none

.edge-shadow
  width: toRem(50px)
  height: 100%
  position: absolute
  &.left
    width: toRem(30px)
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)
    left:  toRem(-2px)
  &.right
    width: toRem(150px)
    background: linear-gradient(-90deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)
    right: 0