@keyframes anim-slideup
  from
    opacity: 0
    transform: translateY(100%)
  to
    transform: translateY(0%)
    opacity: 1

@keyframes anim-slideleft
  from
    opacity: 0
    transform: translateX(100%)
  to
    transform: translateX(0%)
    opacity: 1

@keyframes anim-slideright
  from
    opacity: 0
    transform: translateX(-100%)
  to
    transform: translateX(0%)
    opacity: 1

@keyframes anim-slidedown
  from
    opacity: 1
    transform: translateY(0%)
  to
    transform: translateY(100%)
    opacity: 0

@keyframes anim-fadeout
  from
    opacity: 1
  to
    opacity: 0

@keyframes anim-fadein
  from
    opacity: 0
  to
    opacity: 1


.anim-slide-top
  animation-name: anim-slideup
  animation-duration: 0.4s
  animation-fill-mode: forwards  

.anim-slide-left
  animation-name: anim-slideleft
  animation-duration: 0.4s
  animation-fill-mode: forwards  

.anim-slide-right
  animation-name: anim-slideright
  animation-duration: 0.4s
  animation-fill-mode: forwards  


.video-cover-fadeout
  animation-name: anim-fadeout
  animation-duration: 2s
  animation-fill-mode: forwards  

.anim-fade-in
  animation-name: anim-fadein
  animation-duration: 2s
  animation-fill-mode: forwards  

.anim-delay-100
  animation-delay: 100ms
.anim-delay-200
  animation-delay: 200ms
.anim-delay-300
  animation-delay: 300ms
.anim-delay-400
  animation-delay: 400ms
.anim-delay-500
  animation-delay: 500ms
.anim-delay-600
  animation-delay: 600ms
.anim-delay-700
  animation-delay: 700ms
.anim-delay-800
  animation-delay: 800ms
.anim-delay-900
  animation-delay: 900ms
.anim-delay-1000
  animation-delay: 1000ms