@keyframes anim-slideup-panel
  from
    transform: translateY(100%)
  to
    transform: translateY(0%)
  
.footer-panel
  position: absolute
  width: 100%
  //bottom:  toRem(-64px)
  bottom: 0
  transform: translateY(100%)

  .nav
    top: 50%
    transform: translateY(-50%)
    pointer-events: none
    button
      pointer-events: all

  &.show 
    bottom: 0

  &.topics
    @include below-wi(mobile)
      header
        width: toRem(64px)
        h4
          display: none
        .arrow
          display: none
            
  &.locations
    header
      position: relative
      display: block
      left: calc( (100%/3) + toRem(1px))
      @include below-wi(mobile)
        left: 0
        width: calc(100% - 64px)
      button
        position: relative
        @include below-wi(mobile)
          left: 0
          width: calc(100% - toRem(65px))

    .swiper-slide
      width: calc( (100vw / 5) )

  &.area
    header
      position: relative
      display: block
      left: calc( (100%/3) + toRem(1px))
      @include below-wi(mobile)
        left: toRem(65px)
      button
        position: relative
        @include below-wi(mobile)
          left: 0
          width: calc(100% - toRem(65px))

    .swiper-slide
      width: calc( (100vw / 5) )


  
  header
    margin-top:  toRem(-64px)  
    button:hover
      @apply bg-uigrey-darkhover
    .arrow svg
      transition: transform 0.3s
      transform: rotate(0deg)
    .extra-buttons
      position: absolute
      left: 100%
      bottom: 0

  &.open
    z-index: 2
    transform: translateY(0)
    animation-name: anim-slideup-panel
    animation-duration: 0.4s
    animation-fill-mode: forwards      

    header .arrow svg
      transform: rotate(180deg)

  &.has-info
    aside  
      .swiper-container
        width: calc(100% / 3 * 2)
        @include below-wi(mobile)
          width: 100%

  &.show-gradient
    aside .gradient
      display: block

  aside
    position: relative

    .gradient 
      display: none
      pointer-events: none
      width: toRem(100px)
      height: 100%
      position: absolute
      top: 0
      right: toRem(24px)
      background: linear-gradient(90deg, rgba(51,63,69,0) 0%, rgba(51,63,69,1) 100%)
      z-index: 1
      @include below-wi(mobile)
        right: toRem(8px)      


    .info      
      @include below-wi(mobile)
        width: 100%

    .swiper-container
      width: 100%

    ul
      
      display: flex
      flex-wrap: wrap
      position: relative
      li
        padding: toRem(8px)

      @include below-wi(mobile)      
        width: 100%
        li
          width: 100%




    .ratio
      width: 100%
      height: auto


    .location-item
      transition: transform 0.3s 
      &:hover
        transform: scale(0.98)

      .title
        min-height: 3rem
        line-height: 100%
      .ratio
        width: 100%
        height: auto
        min-height: toRem(180px)

  &.topics-area
    aside 
      .info
        width: calc(100% / 4 * 1 )
      ul
        width: calc(100% / 4 * 3)
        @include below-wi(mobile)
          width: 100%
          flex-direction: column
      li
        width: 33.3%
        @include below-wi(mobile)
          width: 100%
          flex-direction: row

  &.topics-city
    aside 
      .info
        width: calc(100% / 5 * 1 )
      ul
        width: calc(100% / 5 * 4)
        @include below-wi(mobile)
          width: 100%
          flex-direction: column
      li
        width: 25%
        @include below-wi(mobile)
          width: 100%
          flex-direction: row





@keyframes topicSlideIn
  from
    margin-top: toRem(50px)
    opacity: 0
  to
    margin-top: 0
    opacity: 1

@keyframes topicSlideInMobile
  from
    bottom:  toRem(-50px)
    opacity: 0
  to
    bottom: 0
    opacity: 1


.topic-panel
  .panel
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    width: toRem(1000px)
    height: toRem(575px)
    max-width: 90vw
    
    opacity: 0
    animation: topicSlideIn 300ms ease-out 400ms forwards
    @apply shadow-md

    @include below-wi(mobile)
      animation: topicSlideInMobile 300ms ease-out 400ms forwards
      top: auto
      left: 0
      right: 0
      bottom: 0
      transform: none
      width: 100%
      height: auto
      max-width: 100vw

    .image-wrap
      width: 50%
      position: absolute
      height: 100%
      background-color: rgba(255,255,255,0.02)
      img 
        width: 100%
        height: 100%
        object-fit: cover

    .skew-cover
      position: absolute
      height: 100%
      width: 120%
      transform: skew(-6deg,0)
      left:  toRem(-50px)

      @include below-wi(mobile)
        height: 120%
        transform: skew(0,-6deg)
    
  p
    position: relative
    @include below-wi(mobile)
      max-height: toRem(300px)
      overflow-y: scroll
