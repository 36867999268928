$padding: toRem(64px)
$padding-m: toRem(16px)

.mobile-break
  display: none
  @include below-wi(mobile)
    display: inline
    &::after
      content: "\A"
      white-space: pre
      
.rotate-1
  transform-origin: 0 0
  transform: rotate(2deg)
.rotate-2
  transform-origin: 0 0
  transform: rotate(4deg)
.rotate-3
  transform-origin: 0 0
  transform: rotate(6deg)
.rotate--1
  transform-origin: 0 0
  transform: rotate(-2deg)
.rotate--2
  transform-origin: 0 0
  transform: rotate(-4deg)
.rotate--3
  transform-origin: 0 0
  transform: rotate(-6deg)

.image-shadow
  filter:  drop-shadow(0 toRem(1px) toRem(4px) rgba(0, 0, 0, 0.4)) 

.text-shadow  
  filter:  drop-shadow(0 toRem(1px) toRem(4px) rgba(0, 0, 0, 0.4)) 

.content-audio-control
  max-width: toRem(500px)

  .wrap-canvas
    width: toRem(400px)
    max-width: 100%
    canvas
      width: calc(100% - #{toRem(32px)})

  @include below-wi(mobile)
    right: 8px
    left: 8px
    bottom: 8px
    top: auto
    max-width: 100vw
    canvas
      width: calc(100% - #{toRem(32px)})

  &.top-0 .audio-subtitles
    transform: translateY(10px)
    top: 100%
    @include below-wi(mobile)
      top: 0px
      transform: translateY( calc(-100% - 10px))

  .audio-subtitles
    position: absolute
    top: 0px
    transform: translateY( calc(-100% - 10px))
    width: 100%
    text-align: center
    .wrap
      display: inline-block
      background-color: rgba(0,0,0,0.7)
      padding: 4px 10px
      &.hidden
        display: none

.content-title-box 
  position: absolute
  font-size: toRem(32px)
  line-height: 120%
  width: 40%
  font-weight: 600
  //text-shadow: toRem(1px) toRem(2px) toRem(6px) rgba(0,0,0,1)

  @include below-wi(tablet)
    width: 60%

  &.backdrop
    background-color: rgba(0,0,0,0.8)
    padding: toRem(32px)

  // &.backdrop .text-wrap
  //   position: relative
  //   background-color:#333
  //   line-height: 1
  //   color: #fff
  //   display: inline
  //   white-space: pre-wrap
  //   border: 0 solid #333
  //   border-width: 0.4em 0

  //   &:after
  //     background-color:#333
  //     content: ""
  //     position: absolute
  //     top: -0.4em
  //     right: 100%
  //     bottom: -0.4em
  //     width: 0.4em          
  //   span
  //     position: relative
  //     z-index: 1       

  &.wide
    width: 60%

  &.small
    font-size: toRem(24px)

  &.large
    font-size: toRem(45px)
    @include below-wi(tablet)
      font-size: toRem(38px)

  &.extralarge
    font-size: toRem(60px)

  &.null
    top: $padding
    left: $padding


  &.top-left
    top: $padding
    left: $padding * 3

  &.top-mid
    top: $padding
    left: 50%
    transform: translate(-50%,0)

  &.top-right
    top: $padding-m
    right: $padding
    text-align: right
    &.backdrop
      //text-align: left

  &.mid-left
    top: 50%
    transform: translate(0,-50%)
    left: $padding * 3

  &.mid-mid
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    text-align: center
    
  &.mid-right
    top: 50%
    right: $padding
    transform: translate(0,-50%)
    text-align: right
    &.backdrop
      text-align: left


  &.bottom-left
    bottom: $padding
    left: $padding * 2

  &.bottom-mid
    bottom: $padding
    left: 50%
    transform: translate(-50%,0)


  &.bottom-right
    bottom: $padding
    right: $padding
    text-align: right
    &.backdrop
      text-align: left



  @include below-wi(mobile)
    width: auto
    max-width: calc(100% - toRem(64px))

    &.wide
      width: auto      

    &.small
      font-size: toRem(16px)

    &.normal
      font-size: toRem(18px)

    &.large
      font-size: toRem(20px)

    &.extralarge
      font-size: toRem(24px)

    &.top-left,&.top-right
      top: $padding-m
      left: $padding-m
      right: $padding-m

    &.top-mid
      top: $padding
      left: 50%
      transform: translate(-50%,0)
      width: calc(100% - toRem(64px))


    &.mid-left
      top: 50%
      transform: translate(0,-50%)
      left: $padding-m

    &.mid-mid
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      text-align: center
      
    &.mid-right
      top: 50%
      right: $padding-m
      left: $padding-m
      transform: translate(0,-50%)
      text-align: right

    &.bottom-left,&.bottom-right
      bottom: $padding-m
      left: $padding-m
      right: $padding-m

    &.null
      top: $padding-m
      left: $padding-m
      right: $padding-m

    &.bottom-mid
      bottom: $padding
      left: 50%
      transform: translate(-50%,0)


    &.mid-left
      top: 50%
      transform: translate(0,-50%)
      left: $padding-m

    &.end-text
      top: toRem(32px)
      font-size: toRem(24px)


.content-related
  .links
    width: toRem(512px)
    a

    @include below-wi(mobile)
      width: 100%
      height: auto
      bottom: 0
      a>div:first-child
        max-height: toRem(128px)
        min-height: toRem(80px)
        img
          




.share-overlay
  .cover
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    background-color: rgba(0,0,0,0.6)
  .share-icon
    display: flex
    flex-direction: column
    align-items: center
    text-transform: uppercase
    img 
      width: toRem(96px)
      height: toRem(96px)
      margin-bottom: toRem(5px)