@import '../styles/abstracts/vars'
@import '../styles/abstracts/mixins'

@keyframes popup
  from
    opacity: 0
    transform: scale(0)
  to
    transform: scale(1)
    opacity: 1



.inline-help
  position: absolute
  pointer-events: all
  z-index: 99999

  .wrap

    width: 400px
    background-color: theme('colors.uigrey.black')
    display: flex
    box-shadow: 0 5px 10px rgba(#000, 0.9)
    font-size: 1.3rem
    animation-name: popup
    animation-duration: 400ms
    animation-fill-mode: forwards 
    max-width: calc(100vw - 20px)


    &::before
      content: ' '
      background-image: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.909851 30.7051V0.705078H30.9099L0.909851 30.7051Z' fill='%231C2529'/%3E%3C/svg%3E")
      display: block
      //background-color: #f00
      width: 30px
      height: 30px 
      position: absolute
      top: calc(100% - 2px)
      left: 32px
    button, svg
      width: 32px
      height: 32px
      



//    
