.survey

  .survey-bg-wrapper

  button.next
    transform: scale(2)
    transform-origin: bottom right
    @include below-wi(mobile)
      transform: scale(1)

  .pct-number
    font-size: toRem(300px)
    line-height: 110%
    @include below-wi(mobile)
      font-size: 30vw

  .survey-bg
    position: absolute    
    top: 0

  button.yesno
    flex: 1
    min-height: toRem(300px)
    @include below-wi(mobile)    
      min-height: toRem(120px)    

  .bg-shape
    width: 100%
    height: auto
    position: absolute
    top: calc( 100% - 7.5rem)

  .max-height-mobile
    @include below-wi(mobile)    
      max-height: calc(100% - 128px)
      overflow-y: scroll 

  .dot
    width: 6px
    height: 6px
    border-radius: toRem(4px)
    border: toRem(1px) solid rgba(255,255,255,0.4)
    margin-right: toRem(4px)
    @include below-wi(mobile)  
      width: 3px 
      height: 3px
    &.selected
      background-color: #fff    

  .bar-color-0
    background-color: transparentize(#5b6d78,0.1)
  .bar-color-1
    background-color: transparentize(#566872,0.1)
  .bar-color-2
    background-color: transparentize(#51616b,0.1)
  .bar-color-3
    background-color: transparentize(#4a5a63,0.1)
  .bar-color-4
    background-color: transparentize(#43535b,0.1)
  .bar-color-5
    background-color: transparentize(#3b4a53,0.1)
  .bar-color-6
    background-color: transparentize(#334249,0.1)
  .bar-color-7
    background-color: transparentize(#2b393f,0.1)
  .bar-color-8
    background-color: transparentize(#253137,0.1)
  .bar-color-9
    background-color: transparentize(#1f2c31,0.1)
  .bar-color-10
    background-color: transparentize(#1f2c31,0.04)