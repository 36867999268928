//@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;400;600;700&display=swap');

/* fira-sans-100 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 100;
  src: url('fonts/fira-sans-v16-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/fira-sans-v16-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/fira-sans-v16-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/fira-sans-v16-latin-100.woff') format('woff'), /* Modern Browsers */
       url('fonts/fira-sans-v16-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/fira-sans-v16-latin-100.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-regular - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/fira-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/fira-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/fira-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/fira-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/fira-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/fira-sans-v16-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-600 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/fira-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/fira-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/fira-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/fira-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
       url('fonts/fira-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/fira-sans-v16-latin-600.svg#FiraSans') format('svg'); /* Legacy iOS */
}
/* fira-sans-700 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/fira-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('fonts/fira-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/fira-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/fira-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/fira-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/fira-sans-v16-latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'komika_textregular';
  src: url('fonts/komtxt__-webfont.woff2') format('woff2'),
       url('fonts/komtxt__-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  font-family: 'Fira Sans', sans-serif;
  background-color: theme('colors.uigrey.black');
  padding: 0;
  margin: 0;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: contain;
  @apply bg-uigrey-black;
  @apply text-white-default;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  svg {
    animation: rotation 2s infinite linear;
  }
}


@media (-webkit-device-pixel-ratio: 1.10) { body, html { font-size: 16px / 1.10 } }
@media (-webkit-device-pixel-ratio: 1.25) { body, html { font-size: 16px / 1.25 } }


#root {
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
}

.app {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);

  &.tour {
    height: calc((var(--vh, 1vh) * 100) - 64px);

  }

  &.debug{
    border:4px solid #900
  }
}

@import './abstracts/vars';
@import './abstracts/mixins';
@import './scrollbars';
@import './container';
@import './text';
@import './header';
@import './buttons';
@import './icons';
@import './animation';
@import './survey';

@import './map/topicbar';
@import './map/maphandler';

@import './stories/storyhandler';
@import './stories/overlays';
@import './stories/content';
@import './stories/scrollstory';

@import './startmenu';
