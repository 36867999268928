@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'

@keyframes bgfade
  from
    background-color: rgba(0,0,0,0)
    //transform: translate(-50%,-50%)
  to
    //transform: translate(-50%,-50%)
    background-color: rgba(0,0,0,0.5)

@keyframes popup
  from
    transform: scale(0.4)
  to
    transform: scale(1)



.howto-help
  position: fixed
  pointer-events: all
  z-index: 99999
  background-color: rgba(0,0,0,0.5)
  left: 0 
  right: 0
  top: 0
  bottom: 0
  animation-name: bgfade
  animation-duration: 800ms
  animation-fill-mode: forwards 

  .popup
    animation-name: popup
    animation-duration: 300ms
    animation-fill-mode: forwards 
    background-color: theme('colors.uigrey.black')
    width: 100%

  .wrap
    position: absolute
    width: 50%
    height: 60%
    
    display: flex
    box-shadow: 0 5px 20px rgba(#000, 0.9)
    font-size: 1.3rem
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    @include below-wi(mobile)
      width: 100%
      height: 100%
      top: 0
      left: 0
      transform: translate(0,0)

  .inner
    overflow-y: auto
    height: 100%


    // &::before
    //   content: ' '
    //   background-image: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.909851 30.7051V0.705078H30.9099L0.909851 30.7051Z' fill='%231C2529'/%3E%3C/svg%3E")
    //   display: block
    //   //background-color: #f00
    //   background-image: 
    //   width: 30px
    //   height: 30px 
    //   position: absolute
    //   top: calc(100% - 2px)
    //   left: 32px

    // button, svg
    //   width: 32px
    //   height: 32px
      



//    
