@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'

  
@keyframes novel_bg_fadein
  from
    opacity: 0
  to
    opacity: 1

@keyframes novel_info_fadein
  from
    opacity: 0
    transform: translateY(toRem(20px))
  to
    opacity: 1
    transform: translateY(0)

@keyframes novel_bubble_fadein
  from
    opacity: 0
    transform: scale(0.6)
  to
    opacity: 1
    transform: scale(1)

  
.novel
  position: absolute
  width: 100vw
  height: calc(var(--vh, 1vh) * 100)
  //padding-top: toRem(160px)
  overflow: hidden
  background-color: theme('colors.uigrey.black')

  .wrapper
    height: calc((var(--vh, 1vh) * 100) - 8rem)
    display: flex
    position: absolute
    padding-bottom: toRem(48px)
    right: toRem(32px)
    @include below-wi(mobile)
      height: calc((var(--vh, 1vh) * 100) - 6rem)
      padding-bottom: 0
      right: 0

  .copyright-wrap
    bottom: toRem(-32px)
    left: toRem(-8px)
    background-color: transparent
    .copyright      
      svg
        width: toRem(16px)
        height: toRem(160px)
        opacity: 0.4
      &:hover svg
        opacity: 1
    .copyright-layer
      bottom: toRem(2px)
      left: toRem(2px)

  .jump-list
    position: absolute
    background-color: theme('colors.uigrey.black')
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 999
    .wrapper
      padding: 0 0 0 toRem(64px)
      position: relative
      overflow-y: scroll
      height: calc(100% - toRem(96px))
      display: flex
      flex-wrap: wrap
    
    button.list-item
      width: calc(33%)
      padding: toRem(5px)
      >div
        background-color: theme('colors.uigrey.dark')
      img 
        width: toRem(120px)
        height: toRem(50px)
        


  .content
    @include below-wi(mobile)
      width: 100% !important
      &.hide
        display: none

  .novel-screen
    height: 100%
    background-color: theme('colors.uigrey.dark')
    margin-left: toRem(16px)
    display: flex

    animation-name: novel_bg_fadein
    animation-duration: 500ms
    animation-fill-mode: forwards    

    &.idx-2>.content
      opacity: 0.3
    &.idx-3>.content
      opacity: 0.1
    &.idx-4>.content
      opacity: 0.05

    &.menu
      background-color: theme('colors.white.default')
      padding: toRem(20px)
      &.content
        border: toRem(2px) solid #000
      button.nav
        min-height: toRem(64px)


    @include below-wi(mobile)
      width: 100vw !important

    .button-wrap
       background-color: theme('colors.uigrey.black')       
       display: flex
       justify-content: flex-end
       flex-direction: column

       button
         margin-left: toRem(16px)
         background-color: theme('colors.pink.25')
         padding: toRem(16px)
         margin-top: toRem(16px)
         font-size: toRem(30px)
         line-height: 110%
         @include below-wi(mobile)
            font-size: toRem(22px)
    

    .img
      width: 100%
      height: 100%
      object-fit: cover
      opacity: 0
      animation-name: novel_bg_fadein
      animation-delay: 500ms
      animation-duration: 1000ms
      animation-fill-mode: forwards    

      

    .text-box
      position: absolute
      //width: toRem(340px)
      border: toRem(2px) solid #000
      padding: toRem(16px)
      font-size: toRem(28px)
      font-family: 'komika_textregular'
      line-height: 100%
      box-shadow: 0 toRem(4px) toRem(30px) rgba(0, 0, 0, 0.7)
      @include below-wi(mobile)
        font-size: toRem(22px)

      &.intro
        background-color: #454D54
        color: #fff
        animation-name: novel_intro_fadein
        animation-delay: 600ms
        animation-duration: 600ms
        animation-fill-mode: forwards    

        &.null
          bottom: 0

        &.TL
          top:  toRem(-16px)
          left:  toRem(-16px)
          right: toRem(32px)

        &.BL
          bottom: toRem(32px)
          left:  toRem(-16px)
          right: toRem(32px)
        @include below-wi(mobile)
          &.BL
            bottom: toRem(64px)
            left: 0

        &.BR
          bottom: toRem(32px)
          right: toRem(32px)
          left: toRem(32px)
        @include below-wi(mobile)
          &.BR
            bottom: toRem(64px)
            right: toRem(32px)

      &.info
        background-color: #FAD8F0
        color: #000
        width: 100%
        opacity: 0
        bottom: 0
        animation-name: novel_info_fadein
        //animation-delay: 600ms
        animation-duration: 600ms
        animation-fill-mode: forwards   


        &.TL,&.TR
          top: 0
          bottom: auto

        &.BR, &.null, &.MR, &.BL, &.ML, &.MR
          bottom: 0
          padding-right: toRem(64px)          


      &.bubble
        background-color: #B6D3EE
        color: #000
        opacity: 0
        transform: scale(0.6)

        animation-name: novel_bubble_fadein
        //animation-delay: 600ms
        animation-duration: 600ms
        animation-fill-mode: forwards    

        svg
          position: absolute

        svg.top
          bottom: calc(100% - 2px)
        svg.bottom
          top: calc(100% - 2px)

        // &:after
        //   content: url("data:image/svg+xml,%3Csvg width='80' height='91' viewBox='0 0 80 91' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.95801 89.687L28.976 65.1925L55.326 61.4282L77.6222 0.91003L66.9084 72.1419L42.8314 75.0031L34.8995 89.687H1.95801Z' fill='%23B6D3EE'/%3E%3Cpath d='M1.95801 89.687L28.976 65.1925L55.326 61.4282L77.6222 0.91003L66.9084 72.1419L42.8314 75.0031L34.8995 89.687' stroke='black' stroke-width='3'/%3E%3C/svg%3E%0A")
        //   position: absolute
        //   top: toRem(7px)          
        //   transform: translateY(-100%) 



        &.TL
          top: toRem(-32px)
          left: toRem(-16px)
          right: toRem(32px)

        &.ML
          top: 50%
          left: toRem(32px)
          right: toRem(32px)          

        &.TR
          top: toRem(-32px)
          left: toRem(32px)
          right: toRem(32px)

        &.BL,&.MR
          bottom: toRem(32px)
          left: toRem(-16px)
          right: toRem(32px)
          &:after 
            left: 40%

        &.BR
          bottom: toRem(32px)
          right: toRem(96px)
          &:after 
            left: 0%
            transform: translateY(-100%) scaleX(-1) 

