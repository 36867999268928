
.start-menu 
  .title
    font-size: toRem(80px)
    word-break: break-word
    @include below-wi(mobile)
      font-size: toRem(40px)

  &.open .title
    display: none
    
  .cover
    position: absolute
    background: rgb(0,0,0)
    background: linear-gradient(151deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)

  .menu-wrap
    height: toRem(112px)
    transition: height 500ms ease    
    @include below-hi(se)
      margin-top: -40px

  .col-block
    display: block
    width: 100%
    img 
      width: 100%
      height: toRem(250px)
      object-fit: cover
      @include below-wi(mobile)
        height: toRem(150px)
      @include below-hi(se)
        height: toRem(100px)

  &.open
    .col-1      
    .col-2
    .col-3

  .btn-arrow
    position: relative
    display: flex
    align-items: center    
    text-align: left
    padding-right: toRem(20px)
    line-height: 125%
    svg
      flex-shrink: 0

  .circle-banner    
    width: toRem(275px)
    height: toRem(275px)
    background-color: theme('colors.pink.50')
    border-radius: toRem(400px)
    margin-bottom: toRem(50px)
    margin-right: toRem(-50px)
    padding-top: toRem(20px)
    padding-right: toRem(75px)
    padding-left: toRem(75px)
    transition: transform 400ms
    font-size: 1rem

    h3 
      font-size: 1.5em
      font-weight: bold
      line-height: 110%
      padding-bottom: toRem(16px)

    @include below-wi(mobile)
      width: toRem(180px)
      height: toRem(180px)
      margin-bottom: toRem(80px)
      margin-right:  toRem(-30px)
      font-size: 0.7rem
      p,h3
        padding-bottom: toRem(10px)
      svg
        width: toRem(32px)
        height: toRem(32px)
        

    &:hover
      transform: scale(0.95)
    p
      line-height: 130%
      padding-bottom: toRem(10px)
