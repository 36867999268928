@keyframes scaleUp
  from
    opacity: 0
    transform: scale(0.9,0.9)
  to
    opacity: 1
    transform: scale(1,1)
  
.overlay-screen
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: calc(var(--vh, 1vh) * 100)
  overflow: hidden
  animation-name: scaleUp
  animation-duration: 0.4s
  animation-fill-mode: forwards
  
.overlay-slideshow
  .swiper-slide
    position: relative
    width: 100%
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center center
    .meta
      position: absolute
      bottom: 0
      height: toRem(64px)
      width: 100%

      .story-handler.blue & 
        background-color: rgba(255,0,0,0.5)


.swiper-button-prev,.swiper-button-next
  position: absolute
  width: toRem(50px)
  height: toRem(50px)
  bottom: toRem(100px)
  z-index: 99


  background-color: rgba(255,0,0,0.5)



.overlay-footer

  .story-handler.blue & 
    @apply bg-blue-100  

  .hide-block
    transform: translateY(100%)
  .show-block
    transform: translateY(0)

.overlay-video-control
  position: absolute  
  bottom: 0      
  transform: translateY(100%)
  transition: bottom 0.2s ease 1s, transform 0.2s ease 1s
  left: toRem(32px)
  right: toRem(64px)
  @include below-wi(mobile)
    left: 0
    right: 0

  .sub-title
    position: absolute
    bottom: 100%
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-bottom: 4px
    div
      background-color: rgba(0,0,0,0.4)
      padding: 4px 10px
      font-size: 18px

  .captions
    top: 100%
    height: toRem(64px)
    display: flex
    align-items: center
    @include below-wi(mobile)
      font-size: 14px
      max-width: calc(100vw - 128px)

  button
    transition: background-color 1000ms, color 1000ms

  &.active 
    bottom: toRem(64px)  
    transform: translateY(0)

  &:hover
    .progress-bar
      background-color: rgba(255,255,255,0.2)
    button
      background-color: rgba(255,255,255,0.2)

  .progress-bar
    transition: background-color 1000ms, color 1000ms
    height: toRem(64px)
    >div
      background-color: rgba(255,255,255,0.1)    
    .block
      height: toRem(1px)


.overlay-text
  .text-body-wrapper
    overflow-y: scroll

  .text-title
    //font-size: toRem(60px)
    max-width: 60vw
    line-height: 110%
    @include below-wi(mobile)
      max-width: 100%

  .text-body
    font-size: toRem(24px)
    max-width: 60vw

    @include below-wi(mobile)
      font-size: initial
      max-width: 100% //calc(100% - toRem(24px))



