@mixin below-wi($breakpoint)
  @if map-has-key($breakpoints_width, $breakpoint)
    $breakpoint-value: map-get($breakpoints_width, $breakpoint)
    @media (max-width: ($breakpoint-value - 1))
      @content
  @else
    @warn 'Invalid breakpoint: #{$breakpoint}.'


@mixin below-hi($breakpoint)
  @if map-has-key($breakpoints_height, $breakpoint)
    $breakpoint-value: map-get($breakpoints_height, $breakpoint)
    @media (max-height: ($breakpoint-value - 1))
      @content
  @else
    @warn 'Invalid breakpoint: #{$breakpoint}.'


@function toRem($size)
  @return ($size / 16px) * 1rem
  


body
  --device-id: 1    
  @include below-wi(mobile)
    --device-id: 2
  
.translate-center
  transform: translate(-50%,-50%)

.shadow-1
  box-shadow: 0 toRem(5px) toRem(10px) rgba(0, 0, 0, 0.5)
.shadow-2
  box-shadow: 0 toRem(5px) toRem(20px) rgba(0, 0, 0, 0.5)

.gradient-vert-white
  background: linear-gradient(180deg, rgba(#BF332F,1) 0%, rgba(#BF332F,0.1) 100%)