@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'

@keyframes slidein_from_right
  from
    transform: translateX(-100%)
    opacity: 0
  to
    transform: translateX(0)
    opacity: 1


.prison-stats-widget-tag
  position: absolute
  top: 175px
  left: 0
  height: 150px
  @include below-wi(mobile)
    top: 90px
  @include below-hi(se)
    display: none


.prison-stats-widget
  position: absolute
  width: 175px
  top: 175px
  left: 0
  box-shadow: 0 toRem(5px) toRem(20px) rgba(0, 0, 0, 0.8)

  animation-name: slidein_from_right
  animation-duration: 0.4s
  animation-fill-mode: forwards

  &.closed
    animation-direction: reverse

  &:hover .bg-uigrey-dark
    background-color: #546671

  @include below-wi(mobile)
    max-width: 100%
    right: auto
    left: 0
    top: 90px

  @include below-hi(se)
    display: none

