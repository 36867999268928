
.text-link
  text-decoration: none
  position: relative
  &:hover 
    opacity: 0.6 !important

  &.arrow
    &:before 
      content: ''
      position: absolute
      width: toRem(20px)
      height: toRem(11px)
      top: 50%      
      left:  toRem(-20px)
      transform: translateY(-50%)
      display: inline-flex
      align-items: center
      background-repeat: no-repeat
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.90032 0.672729L5.58026 1.98397L8.44386 4.84757L0.861084 4.84757L0.861084 6.69852L8.44386 6.69852L5.58026 9.56211L6.90032 10.8734L11.989 5.77304L6.90032 0.672729Z' fill='white'/%3E%3C/svg%3E%0A")


.text-shadow-1
  text-shadow: toRem(1px) toRem(2px) toRem(6px) rgba(0,0,0,1)    
.text-shadow-2
  text-shadow: toRem(2px) toRem(4px) toRem(12px) rgba(0,0,0,1)    


.error
  color:#f00
  padding: toRem(30px)
  box-shadow: 0 0 toRem(30px) #000

.text-bg-backdrop
  background-color: rgba(0,0,0,0.8)
.text-bg-backdrop-1
  background-color: rgba(0,0,0,0.5)

.text-bg-blur
  backdrop-filter: blur(toRem(10px))

.inner-html-content
  h1 
    font-size: toRem(64px)
    line-height: 100%
  h2 
    font-size: toRem(50px)
    line-height: 120%
  h3 
    font-size: toRem(30px)
    line-height: 120%
  h4 
    font-size: toRem(20px)
    line-height: 120%
  h5 
    font-size: toRem(14px)
    line-height: 120%


span.wrap
  break-after: always
  &.desk
    display: inline-block
    visibility: hidden
    @include below-wi(mobile)
      display: none  
  &.mobile
    display: none
    @include below-wi(mobile)
      display: inline-block