@import '../styles/abstracts/vars'
@import '../styles/abstracts/mixins'

span.glossary 
  color: theme('colors.blue.100')
  cursor: pointer
  &:hover
    opacity: 0.5

.bg-blue-50 span.glossary
  color: theme('colors.yellow')

.glossary-overlay
  position: absolute
  z-index: 10
  top: 0
  left: 0
  bottom: 0
  right: 0

  .cover
    width: 100%    
    height: 100% 
    background-color: rgba(0,0,0,0.95)
    

  .window
    position: absolute
    background-color: theme('colors.uigrey.black')
    top: toRem(128px)
    right: toRem(64px)
    //width: toRem(583px)
    //width: 50vw
    //height: toRem(400px)
    bottom: toRem(64px)
    transition: height 200ms
    width: 600px
    max-width: 100%

    @include below-wi(mobile)
      top: toRem(64px)    
      left: 0
      right: 0

    &.list
      height: calc(100vh - #{toRem(192px)})

    video
      object-fit: contain
    .video-poster
      object-fit: contain
    .overlay-video-control
      left: 0
      right: 0
      bottom: 0

.glossary-list
  height: 100%
  overflow-y: scroll
  width: 100%
  button
    height: toRem(74px)
    width: 100%
  