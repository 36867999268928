// A map of breakpoints.
$breakpoints_width: ( 
    mobile-xs   : 640px,
    mobile      : 768px,
    tablet      : 1200px,
);

// A map of breakpoints.
$breakpoints_height: ( 
    se    : 600px,
);


//container sizes
$containersWidth: ( 
    container-extrawide : 1280px,
    container-wide : 1160px,
    container : 960px,
    container-narrow: 640px
);

:root {
  --container-wide: 1160px;
  --container: 960px;
}