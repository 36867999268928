@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'

  
.map-text-window
  position: absolute
  top: toRem(96px)
  bottom: toRem(64px)
  right: toRem(64px)
  width: 30vw
  display: flex
  flex-direction: column
  padding-bottom: toRem(32px)

  @include below-wi(mobile)
    width: 100%
    top: unset
    bottom: 0
    left: 0
    right: 0
    height: 300px

  .content
    overflow-y: scroll
