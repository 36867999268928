@import '../styles/abstracts/vars'
@import '../styles/abstracts/mixins'

.copyright-wrap
  position: absolute
  bottom: toRem(12px)
  left: toRem(12px)
  width: toRem(32px)
  height: toRem(32px)
  border-radius: toRem(32px)
  background-color: rgba(0,0,0,0.5)
  z-index: 2
  .copyright
    font-size: toRem(20px)
    font-weight: bold
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
.copyright-layer
  width: 100%
  position: absolute
  bottom: toRem(32px)
  left: toRem(32px)
  width: toRem(300px)
  padding: toRem(16px) toRem(32px)
  z-index: 999
  background-color: rgba(0,0,0,0.8)
  font-style: normal !important
  i
    font-style: normal