@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'


.topic-item
  transition: transform 0.3s 
  &:hover
    transform: scale(0.98)
  .image-wrap        
    width: 100%
    max-height: toRem(130px)
    position: relative
    flex-shrink: 0
    @include below-wi(mobile)
      width: toRem(128px )
  p
    line-height: 120%