@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'

.scrollmap
  .area
    width: 100%
    height: 100%
    overflow: hidden

  .frontcover
    position: absolute
    background-size: cover
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2



  .backcover
    position: absolute
    width: 100%
    height: 100%


  $height: 50vh
  $width: 50vw


  .map-screen 
    width: 100%
    height: 100%
    position: absolute
    //border: toRem(1px) solid rgba(200,0,0,0.3)
    z-index: 1

    &.active
      z-index: 2

    .screen-wrapper
      position: absolute
      top: toRem(96px)
      bottom: toRem(64px)
      left: toRem(32px)
      right: toRem(32px)
      @include below-wi(mobile)
        bottom: toRem(64px)
        left: 0
        right: 0


    &.xpos-0
      left: 0
    &.xpos-1
      left: $width * 1
    &.xpos-2
      left: $width * 2
    &.xpos-3
      left: $width * 3
    &.xpos-4
      left: $width * 4
    &.xpos-5
      left: $width * 5
    &.xpos-6
      left: $width * 6
    &.xpos-7
      left: $width * 7
    &.xpos-8
      left: $width * 8
    &.xpos-9
      left: $width * 9
    &.xpos-10
      left: $width * 10

    &.ypos-0
      top: 0
    &.ypos-1
      top: $height * 1
    &.ypos-2
      top: $height * 2
    &.ypos-3
      top: $height * 3
    &.ypos-4
      top: $height * 4
    &.ypos-5
      top: $height * 5
    &.ypos-6
      top: $height * 6
    &.ypos-7
      top: $height * 7
    &.ypos-8
      top: $height * 8
    &.ypos-9
      top: $height * 9
    &.ypos-10
      top: $height * 10

    &.active 
      .content-image,.content-title-box 
        opacity: 1



  .content-image
    z-index: -1
    //opacity: 0.4
    transition: opacity 1000ms



  .story-title
    font-weight: 400
    padding: toRem(20px)
    background-color: rgba(0,0,0,0.5)
    width: 50%
    @include below-wi(mobile)
      width: 100%


  .content-related
    .flex-1.items-end
      flex: 0
      padding: 0
    .links
      padding-top: toRem(20px)
      padding-bottom: toRem(40px)
      background-color: rgba(0,0,0,0.5)
      @include below-wi(mobile)
        padding-left: toRem(16px)
        padding-right: toRem(16px)
    .title
      background-color: theme('colors.petrol.50')

  .content-title-box 
    opacity: 0.1
    transition: opacity 1000ms

    &.top-left
      top: 0
      left: toRem(32px)

    &.top-mid
      top: 0

    &.top-right
      top: 0
      right: 0

    &.mid-left
      left: toRem(32px)

    &.mid-right
      right: 0
      transform: translate(0,-50%)

    &.bottom-left
      bottom: 0
      left: toRem(32px)

    &.bottom-mid
      bottom: 0

    &.bottom-right
      bottom: 0
      right: toRem(32px)


    &.normal
      font-size: toRem(24px)

    &.large
      font-size: toRem(32px)      

    @include below-wi(mobile)
      width: calc(100% - 64px)

      &.normal
        font-size: toRem(18px)

      &.large
        font-size: toRem(24px)

      &.bottom-right
        bottom: toRem(64px)

    // &.backdrop .text-wrap
    //   background-color: transparent
    //   border-width: 0
    //   &:after
    //     display: none