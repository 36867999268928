@keyframes slidein
  from
    left: toRem(20px)
    opacity: 0
  to
    left: 0
    opacity: 1

  
.header-navigation
  position: absolute
  top: 0
  width: 100%
  z-index: 20
  padding-right: 32px
  transition: padding-right 400ms, padding-top 400ms
  padding-top: 32px

  @include below-wi(mobile)
    padding-top: 0px
    padding-right: 0px

  &.in-story
    padding-right: toRem(64px)
    padding-top: 0px


.nav-button
  display: flex
  align-items: center
  opacity: 0.5

  &:hover
    opacity: 1

  div
    text-align: left
    line-height: 100%

  &.burger
    opacity: 1

  &.active
    opacity: 1
    //pointer-events: none

.menu-wrapper

  .menu-header
    background-color: rgba( #1C2529, 0.95)
    height: toRem(128px)
    opacity: 0
    transition: opacity 0.4s
    position: fixed
    width: 100%
    top: 0
    z-index: 1
    @include below-wi(mobile)
      height: toRem(96px)

  .cover 
    
    background-color: rgba( #1C2529 ,1 ) 
    width: 0%
    height: 100%
    top: 0
    right: 0
    position: fixed
    transition: transform 0.3s, width 0.3s
    transition-timing-function: ease
    transform-origin: 0 0
    transform: skewX(10deg)


  .menu  
    top: 0
    left: 0
    position: fixed
    right: 0
    display: none
    max-height: 100%
    overflow-x: hidden
    padding-top: toRem(128px)
    padding-bottom: toRem(64px)
    @include below-wi(mobile)    
      overflow-y: scroll

    a
      display: block
      position: relative
      left: toRem(20px)
      opacity: 0

      &.level-1
        margin-left: toRem(20px)
        font-size: toRem(24px)
        color: #999
        margin-bottom: toRem(10px)


    .text-h3 a::before
      top: 1rem

    .large a
      //font-size: toRem(30px)
      //font-weight: 700
      //text-transform: uppercase

  &.show 
    .cover
      width: 100%
      transform: skewX(0deg)
    .menu 
      display: block
      a
        animation-name: slidein
        animation-duration: 0.4s
        animation-fill-mode: forwards
    .menu-header
      opacity: 1
    


.shiftup
  transform: translateY(0)
  transition: transform ease 400ms

.header-navigation.header-hide .shiftup
  transform: translateY(toRem(-128px))
    
.header-navigation.header-sticky .shiftup
  transform: translateY(toRem(-128px))
  &.logo
    transform: translateY(0)