@import '../../styles/abstracts/vars'
@import '../../styles/abstracts/mixins'


.prison-stats
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  max-height: 100%
  overflow-y: scroll  
  @include below-wi(mobile)
    max-width: 100%
    right: 0
    left: 0

  .header-cover
    background-color: #343F45
    height: toRem(128px)
    opacity: 0.9
    z-index: 11


  .inner
    max-width: toRem(1600px)
    margin-left: auto
    margin-right: auto


  .summary-bar
    background-color: rgba(255,255,255,0.1)
    height: toRem(16px)
    position: relative
    .summary-block
      position: absolute
      right: 0
      background-color: #fff
      height: 100%

  .graph-wrapper
    height: toRem(200px)

  .mini-block
    width: 16px
    heigth: 16px
    background-color: #fff
    margin-right: 4px

  .diagram
    .icon
      height: toRem(140px)

    .bg-lines
      position: absolute
      z-index: 0
      height: 100%
      display: flex
      flex-direction: column
      width: 100%
      >div
        flex: 1
        text-align: right
        font-size: toRem(9px)
        border-top: toRem(1px) solid #68808D
        &:last-child
          border-bottom: toRem(1px) solid #68808D
    .bars
      position: relative
      .bar
        display: flex
        flex-direction: column
        justify-content: flex-end
        padding: 0 toRem(5px)
        &:hover
          opacity: 0.9
        @include below-wi(mobile)
          padding: 0 2px


        flex: 1
        .inner
          background-color: #fff
          width: 100%
          &.a
            opacity: 0.1
          &.b
            opacity: 0.5
          &.b
            opacity: 0.7

        &.hover .inner
          background-color: #94226B



  .dropdown
    .field
      width: 100%
    .list button
      min-height: toRem(64px)      

  .draggeBlockWrapper
    height: toRem(75px)